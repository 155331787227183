module controllers {
    export module logistics {

        interface ILivetrackingSettingUpdateCtrlScope extends ng.IScope {
        }

        interface ILivetrackingSettingUpdateParams extends ng.ui.IStateParamsService {
            liveTrackingSettingId: number;
        }

        export class livetrackingSettingUpdateCtrl {

            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                "$uibModal",
                "LiveTrackingSettingService",
                "$state",
                "$stateParams",
                "$transitions",
                'countryService',
                '$anchorScroll',
                'transportModeService',
                '$http',
                "ENV"
            ];

         
            TrackingProviderAdditionalAttributes: interfaces.logistics.ITrackingProviderAttribute[] = [];
            
            
            ctrlName: string = "Live Tracking Setting";
            liveTrackingSettingId: number;
            selectedTab: number = 0;

            liveTrackingSetting: interfaces.logistics.ILiveTrackingSetting;
            breadCrumbDesc: string;
            public isLoadingDetail: boolean = false;

            constructor(private $scope: ILivetrackingSettingUpdateCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private liveTrackingSettingService: interfaces.logistics.ILiveTrackingSettingService,
                private $state: ng.ui.IStateService,
                private $stateParams: ILivetrackingSettingUpdateParams,
                private $transitions: ng.ui.core.ITransition,
                public countryService: interfaces.applicationcore.ICountryService,
                private $anchorScroll: ng.IAnchorScrollService,
                public transportModeService: services.applicationcore.transportModeService,
                private $http: ng.IHttpService,
                private ENV: interfaces.applicationcore.serverConfig
            ) {


                this.liveTrackingSettingId = $stateParams.liveTrackingSettingId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadLiveTrackingSetting(this.liveTrackingSettingId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'livetrackingsetting.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });


            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
                 }

                 loadTransportModes() {
                    return this.transportModeService.getDropdownList().query({
                    }, (resultList) => {
                    }).$promise;
                }                 

            loadLiveTrackingSetting(liveTrackingSettingId: number){
                return this.liveTrackingSettingService.getLiveTrackingSetting(liveTrackingSettingId).query((data: interfaces.logistics.ILiveTrackingSetting) => {

                    this.liveTrackingSetting = data;

                    this.TrackingProviderAdditionalAttributes = <interfaces.logistics.ITrackingProviderAttribute[]>JSON.parse(this.liveTrackingSetting.TrackingProviderAttributesJSON);                  

                    let attributes = <interfaces.logistics.IAttribute[]>JSON.parse(this.liveTrackingSetting.AttributesJSON);

                    if (attributes)
                    {
                        for (let attribute of attributes) {
                            let attribute1 = this.TrackingProviderAdditionalAttributes.find((a) => a.Id === attribute.Id);
                            if (attribute1) {
                                attribute1.Value = attribute.Value;
                            }
                        }
                    }
                    
                    if (this.liveTrackingSetting.Entity && this.liveTrackingSetting.TransportMode)
                        this.breadCrumbDesc = this.liveTrackingSetting.Entity.Code + " - " + this.liveTrackingSetting.TransportMode.Description;
                    else
                        this.breadCrumbDesc = "Create New Live Tracking Setting"

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        this.isLoadingDetail = false;
                    });


                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

          
            

            trackingProviderChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model){               

                var url = this.ENV.DSP_URL + 'LiveTrackingSetting/GetTrackingProviderAdditionalAttributes?trackingProvider='+model.Description;
                return   this.$http({
                    method: 'GET',
                    responseType: 'text',
                    url: url,
                }).then((response) => {
                       this.liveTrackingSetting.TrackingProviderAttributesJSON = response.data as string;
                       this.TrackingProviderAdditionalAttributes = <interfaces.logistics.ITrackingProviderAttribute[]>JSON.parse(this.liveTrackingSetting.TrackingProviderAttributesJSON);
                });     

                }
            }



            loadTrackingProviders() {
                return this.liveTrackingSettingService.getTrackingProviders().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }
           

            SaveliveTrackingSetting(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                let Attributes = this.TrackingProviderAdditionalAttributes.map((attribute) => {

                    if (!attribute.TransportMode || attribute.TransportMode === this.liveTrackingSetting.TransportMode.Id )
                        return { Id: attribute.Id, Value: attribute.Value };
                });

                let AttributesJSON = JSON.stringify(Attributes.filter((i)=>i));

                this.liveTrackingSetting.AttributesJSON = AttributesJSON;

                this.liveTrackingSettingService.save().save(this.liveTrackingSetting, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);
   
                    this.liveTrackingSettingId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            isValid(){
                var isFormValid : boolean = false;

                if (!this.liveTrackingSetting){
                    return false;
                }

                if (!this.liveTrackingSetting.Entity  || !this.liveTrackingSetting.TransportMode || !this.liveTrackingSetting.TrackingProvider){
                    isFormValid = false;
                    return isFormValid;
                }

                

                if (this.liveTrackingSetting.Entity.Id>0 && this.liveTrackingSetting.TransportMode.Id>0 && this.liveTrackingSetting.TrackingProvider.Id>0){
                    isFormValid = true;                   
                }

            
                if (this.TrackingProviderAdditionalAttributes)
                {
                    for (let attribute of this.TrackingProviderAdditionalAttributes) {
                        if (attribute.Mandatory && !attribute.Value){
                           
                            if (attribute.TransportMode && attribute.TransportMode === this.liveTrackingSetting.TransportMode.Id){
                                isFormValid = false;
                            }
                                
                        }
                    }
                }
              
                return isFormValid;

            }

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'livetrackingsetting.update'
                },
                    () => {
                        return this.SaveliveTrackingSetting().then((data: boolean) => {
                            if (data) {
                                this.loadLiveTrackingSetting(this.liveTrackingSettingId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.LiveTrackingSetting.Update", { liveTrackingSettingId: this.liveTrackingSettingId });
                            }
                        });
                    });

            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'livetrackingsetting.update'
                },
                    () => {
                        return this.SaveliveTrackingSetting().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }


        }

         angular.module("app").controller("livetrackingSettingUpdateCtrl", controllers.logistics.livetrackingSettingUpdateCtrl);    
    }
}